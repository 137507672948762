if(window.location.pathname == '/area-de-cobertura')
{
    var bairros;

    $.get(api+'/cobertura',function(response){
        bairros = response.data;
        show(bairros);
    });

    $('.cobertura__form').submit(function(e){
        e.preventDefault();
        $('.cobertura__erro').hide();
        $('.cobertura__success').hide();
        let cep = $(this).find('input').val().toLowerCase();
    
        $.get(api+'/frete/correio/'+cep,function(retorno_cep){ 
            if(!retorno_cep.erro)
            {
                $.get(api+'/frete/calcular/'+cep,function(retorno_frete)
                {
                    $('.cobertura__success').html('Bairro: '+retorno_cep.bairro+'<br>'+'Entrega disponível: '+retorno_frete.data[0].titulo).show();
                });
            }
            else
            {
                $('.cobertura__erro').show();
            }
        })
        .fail(function(err){
            $('.cobertura__erro').show();
            // show(bairros);
        });

        // let retorno = [];

        // for(var i in bairros)
        // {
        //     let bairro = bairros[i].titulo.toLowerCase();
            
        //     if(bairro.indexOf(busca) > -1)
        //         retorno.push(bairros[i]);
        // }
        // if(retorno.length)
        //     show(retorno);
        // else
        // {
        //     $('.cobertura__erro').show();
        //     show(bairros);
        // }
    })


    function show(dados)
    {
        let html = '';

        for(var i in dados)
        {
            html +=  '<li class="cobertura__bairro">'+dados[i].titulo+'</li>';
        }

        $('.cobertura__list').html(html);
    }
}