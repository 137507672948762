
/**
 * triggers, targets,
 * triggerClass, targetClass
 */
export default (config = { triggers: '[tab-trigger]', targets: '[tab-target]', triggerClass: 'current', targetClass: 'current' }) => {
    const tabTriggers = document.querySelectorAll(config.triggers);
    const tabTargets = document.querySelectorAll(config.targets);

    tabTriggers.forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault();
            const targetTag = trigger.getAttribute('tab-trigger');

            if (!targetTag) {
                return;
            }

            tabTargets.forEach(target => {
                target.classList.remove(config.targetClass);
                if(target.getAttribute('tab-target') === targetTag) {
                    target.classList.add(config.targetClass);
                }
            });
            tabTriggers.forEach(trigger => {
                trigger.classList.remove(config.triggerClass);
                if(trigger.getAttribute('tab-trigger') === targetTag) {
                    trigger.classList.add(config.triggerClass);
                }
            });
        });
    });
};

