import 'owl.carousel';
import { carrinho_atualiza } from "./carrinho";
var config = require("./globals");

function pushStateUrl(hash) {

    var url = {
        data: '',
        title: hash,
        pathname: hash
    }

    window.history.pushState(url.data, url.title, url.pathname);
}

var htmlofertas = $('.produtos').html();

$.get(api+'/produto/destaques',function(result){
    for(let i in result.data)
    {
        let destaque = result.data[i];
        let produtos = `<h3 class="destaques__title">${destaque.titulo}</h3>
                        <div class="destaques owl-carousel">`;
        produtos += config.template_produtos({titulo:destaque.titulo},destaque.produtos);
        produtos += '</div>';
        htmlofertas += produtos;
    }
    let categoria_slug = window.location.pathname.replace('/categoria/','');
    if(categoria_slug == '/')
    {
        $('.produtos').html(htmlofertas);
        carrouselDestaques();
    }
},"json");

$('.categorias__link').on('click',function(e){
    e.preventDefault();
    $('.categorias__link').removeClass('categorias__link--active');
    $(this).addClass('categorias__link--active');
})

$('.categorias__item').on('click',function(e){
    let id = $(this).attr('data-categoriaId');

    if(id == 0)
    {
        $('.produtos').addClass('home__produtos--destaque');
        $('.produtos').html(htmlofertas);
        carrouselDestaques();
        pushStateUrl('/');
    }
    else
    {
        $('.produtos').removeClass('home__produtos--destaque');
        $.each(document.categorias.data,function(i,data){
            if(data.id == id)
            {
                let slug = data.slug;
                let produtos = data.produtos;

                let produto_html = config.template_produtos(data,produtos);
                $('.produtos').html(produto_html);
                pushStateUrl('/categoria/'+slug);
            }
        });
    }
    carrinho_atualiza();
})


if(window.location.pathname.indexOf('/categoria/') > -1)
{
    let categoria_slug = window.location.pathname.replace('/categoria/','');

    config.produtos.done(function(){
        $('[data-slug="'+categoria_slug+'"]')
            .trigger('click')
            .find('a').trigger('click');
    });
}

function carrouselDestaques(){
    $(".destaques").owlCarousel({
        margin: 44,
        items: 4,
        stagePadding: 50,
        autoWidth:true,
    });
}
