$.validate({ lang: 'pt', modules: 'date, brazil' });


$('input[data-validation=cpf]').mask('000.000.000-00');
$('input[data-validation=cep]').mask('00000-000');
$('input[name=telefone], input[name=celular], input[name=telefone-responsavel]'
).mask('(00) 00000-0000');

$('input[name=cartao_numero]').mask('0000 0000 0000 0000');

$('input[name=mes]').mask('00');
$('input[name=ano]').mask('0000');
$('input[name=cvv]').mask('0000');
