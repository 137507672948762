var config = require("./globals");

if(window.location.pathname == '/conta')
{
    function exibir_enderecos()
    {
        // $('.conta__enderecos div[data-endereco]').remove();
        
        $.get(api+'/endereco',function(response){
            let enderecos = response.data;

            if(enderecos.length == 0)
            {
                $('.conta__container--hidden').removeClass('conta__container--hidden');
            }
            else
            {
                let enderecos_html = 
                    `${enderecos.map(endereco => 
                        `
                        <div class="container conta__container" data-endereco>
                            <h3 class="subtitle conta__subtitle conta__subtitle--enderecos">Meus endereços</h3>
                            <form class="form endereco__form endereco__form--editar" method="post" action="#" disabled data-enderecoid="${endereco.id}">
                                <div class="conta__endereco">
                                    <h4 class="title endereco__title">${endereco.titulo}</h4>
                                    <input type="hidden" name="titulo" value="${endereco.titulo}" />
                                    <a href="" class="button endereco__editar button--outline button--thin">Editar</a>
                                </div>
                                <div class="form__group">
                                    <div class="form__inputs">
                                        <div class="input input--active">
                                            <label class="input__label">Logradouro</label>
                                            <input class="input__field" name="logradouro" value="${endereco.logradouro}" disabled data-validation="required" type="text">
                                        </div>
                                        <div class="input input--active endereco__numero">
                                            <label class="input__label">N.ª</label>
                                            <input class="input__field" name="numero" value="${endereco.numero}" disabled data-validation="required" type="text">
                                        </div>
                                    </div>
                                    <div class="form__inputs">
                                        <div class="input input--active">
                                            <label class="input__label">Bairro</label>
                                            <input class="input__field" name="bairro" value="${endereco.bairro}" disabled data-validation="required" type="text">
                                        </div>
                                        <div class="input input--active">
                                            <label class="input__label">CEP</label>
                                            <input class="input__field" name="cep" value="${endereco.cep}" disabled data-validation="cep" type="text">
                                        </div>
                                    </div>
                                    <div class="form__inputs">
                                        <div class="input input--active">
                                            <label class="input__label">Cidade</label>
                                            <input class="input__field" name="cidade" value="${endereco.cidade}" disabled data-validation="required" type="text">
                                        </div>
                                        <div class="input input--active">
                                            <label class="input__label">UF</label>
                                            <input class="input__field" name="uf" value="${endereco.uf}" disabled data-validation="required" type="text">
                                        </div>
                                    </div>
                                    <div class="input input--active">
                                        <label class="input__label">Referência</label>
                                        <input class="input__field" name="referencia" value="${endereco.referencia?`${endereco.referencia}`:``}" disabled data-validation="required" type="text">
                                    </div>
                                </div>
                                <div class="endereco__actions">
                                    <button class="button button--thin button--disabled endereco__salvar" type="submit">Salvar</button>
                                    <a href="" class="endereco__cancelar endereco__cancelar--editar endereco__cancelar--hidden">Cancelar</a>
                                </div>
                                <a href="" class="button button--outline endereco__adicionar" >Adicionar endereço</a>
                            </form>
                        </div>
                        `).join('')}
                    `;
        
                $('.conta__enderecos').prepend(enderecos_html);
                $('.endereco__adicionar:not(:last)').hide();
                $('.conta__subtitle--enderecos:not(:first)').hide();

            }
            
        });
    }

    exibir_enderecos();

    $('.conta__enderecos').on('click','.endereco__editar', function(e){
        e.preventDefault();
        let $form = $(this).closest('form');
        $(this).addClass('button--disabled');
        $form.removeAttr('disabled');
        $form.find('input').prop('disabled',false);
        $form.find('.endereco__salvar').removeClass('button--disabled');
        $form.find('.endereco__cancelar').removeClass('endereco__cancelar--hidden');
    })

    $('.conta__enderecos').on('click','.endereco__cancelar--editar', function(e){
        e.preventDefault();
        let $form = $(this).closest('form');
        $(this).addClass('endereco__cancelar--hidden');
        $form.attr('disabled',true);
        $form.find('input').prop('disabled',true);
        $form.find('.endereco__salvar').addClass('button--disabled');
        $form.find('.endereco__editar').removeClass('button--disabled');
    })

    $('.conta__enderecos').on('click','.endereco__adicionar', function(e){
        e.preventDefault();
        $(this).addClass('button--disabled');
        $('.conta__container--hidden').removeClass('conta__container--hidden');
    })

    $('.conta__enderecos').on('click','.endereco__cancelar--novo', function(e){
        e.preventDefault();
        $('[data-endereco-novo]').addClass('conta__container--hidden');
        $('.endereco__adicionar').removeClass('button--disabled');
    })

    

    $('.conta__enderecos').on('submit','.endereco__form--editar', function(e){
        e.preventDefault();

        //se tiver desativado não faz nada
        if($(this).has('[disabled]').length)
            return true;

        let endereco_id = $(this).attr('data-enderecoid');
        let dados = $(this).serialize();
        
        $.ajax({
            type: 'POST', 
            dataType: 'json',
            url: api+'/endereco/'+endereco_id,
            headers: {"X-HTTP-Method-Override": "PUT"},
            data: dados
        })
        .done(function(response){
            config.alerta('sucesso','O endereço "'+response.data.titulo+'" foi alterado!');
        })
        .fail(function(data){

            let response = data.responseJSON;

            let error = response.errors[Object.keys(response.errors)[0]];
            let message = error[0];

            config.alerta('erro',message);
        })
    })

    $('.endereco__novo').on('submit', function(e){
        e.preventDefault();

        let form = $(this);
        let div = $(this).parent();

        let dados = $(this).serialize();
        
        $.ajax({
            type: 'POST', 
            dataType: 'json',
            url: api+'/endereco',
            data: dados
        })
        .done(function(response){

            exibir_enderecos();
            div.addClass('conta__container--hidden');
            form.find('input').val('');

            config.alerta('sucesso','O endereço "'+response.data.titulo+'" criado!');
        })
        .fail(function(data){
            let response = data.responseJSON;

            let error = response.errors[Object.keys(response.errors)[0]];
            let message = error[0];

            config.alerta('erro',message);
        })
    })

}