import moment from 'moment';

if(window.location.pathname == '/pedidos')
{

    //exibir último pedido
    $.get(api+'/pedido',function(response){
        let total = response.data.length;
        if(total > 0)
        {
            exibir_pedidos_abertos(response.data)
            exibir_historico(response.data)
        }
        else
        {
            $('.pedidos__historico').hide();
            $('.pedidos__vazio').removeClass('pedidos__vazio--hidden')
        }
    })

    function exibir_pedidos_abertos(pedidos)
    {
        let abertos_html = `
        ${pedidos.map (pedido =>
            `${pedido.situacao != status_entregue?`
                <h3 class="subtitle pedidos__subtitle">Status do pedido: <strong>#${pedido.id} (às ${moment(pedido.created_at.date).format('HH:mm [em] DD/MM/YYYY')})</strong></h3>
                <div class="steps pedidos__steps">
                    <ul class="steps__list">
                        <li class="steps__item steps__item--avaliacao ${pedido.situacao == status_aberto?`steps__item--active`:`steps__item--ok`}">
                            <span>Em avaliação</span>
                        </li>
                        <li class="steps__item steps__item--producao 
                            ${pedido.situacao == status_producao?`steps__item--active`:``}
                            ${pedido.situacao == status_saiu || pedido.situacao == status_entregue ?`steps__item--ok`:``}
                        ">
                            <span>Em produção</span>
                        </li>
                        <li class="steps__item steps__item--saiu 
                            ${pedido.situacao == status_saiu?`steps__item--active`:``}
                            ${pedido.situacao == status_entregue ?`steps__item--ok`:``}
                        ">
                            <span>Saiu para entrega</span>
                        </li>
                        <li class="steps__item steps__item--entregue ${pedido.situacao == status_entregue?`steps__item--active`:``}"><span>Entregue</span></li>
                    </ul>
                </div>
                <span class="pedidos__description">
                ${pedido.pedido_produtos.map (produto =>
                    `${produto.titulo}: ${parseInt(produto.quantidade)}KG`
                ).join(' - ')}
                </span>
                <a href="/pedido/${pedido.id}" class="pedidos__ver-detalhes">Ver detalhes</a>
            `:``}`).join('')}
        `;

        if(abertos_html.trim() == '')
        {
            $('.pedidos__vazio').removeClass('pedidos__vazio--hidden')
        }

        $('.pedidos__content .content__container').append(abertos_html);
    }

    function exibir_historico(pedidos)
    {
        let historico_html = `
        ${pedidos.map (pedido =>
            `${pedido.situacao == status_entregue?`
                <div class="card">
                    <div class="card__header">
                        <span class="card__pedido">Pedido #${pedido.id}</span>
                        <span class="card__data">${moment(pedido.created_at.date).format('HH:mm [em] DD/MM/YYYY')}</span>
                    </div>
                    <div class="card__body">
                        <span class="card__detalhes">Detalhes (${pedido.pedido_produtos.length} itens):</span>
                        <ul class="card__itens">
                            ${pedido.pedido_produtos.map (produto =>
                            `<li class="card__item">${produto.titulo} <span>${parseInt(produto.quantidade)}kg</span></li>`
                            ).join('')}
                        </ul>
                        <a href="/pedido/${pedido.id}" class="pedidos__link">Ver detalhes</a>
                        <a href="#" data-pedidoid="${pedido.id}" class="button pedidos__comprar">Comprar de novo</a>
                    </div>
                </div>
            `:``}`).join('')}
        `;

        if(historico_html.trim() == '')
        {
            $('.pedidos__historico').hide();
        }

        $('.pedidos__historico .container').html(historico_html);

    }

    $('.pedidos__historico').on('click','.pedidos__comprar',function(e){
        e.preventDefault();

        let pedido_id = $(this).attr('data-pedidoid');

        $.get(api+'/pedido/'+pedido_id,function(response){

            Cookies.set('pedido', {});
            Cookies.set('carrinho', {});

            // console.log(response);

            let pedido = {};
            let carrinho = {};

            pedido.endereco_id = response.endereco_id;
            pedido.pagamento = { tipo : response.pagamentos[0].tipo,
                                troco : response.pagamentos[0].troco,
                                titulo : '"Crédito / Débito"'}

            $.each(response.pedido_produtos, function(i,produto){
                carrinho[produto.produto_id] = parseInt(produto.quantidade);
                // pedido.carrinho.push({produto_id:produto.produto_id, quantidade:produto.quantidade});
            })

            // console.log(pedido)
            // console.log(carrinho)

            Cookies.set('pedido', pedido);
            Cookies.set('carrinho', carrinho);

            window.location = '/pedido/resumo';
        })
    })
}
