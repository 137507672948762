import iziToast from 'izitoast';

if (!Cookies.get('pedido')) {
    Cookies.set('pedido', {
        valor_produtos: 0,
        total: 0,
        endereco_id: null,
        frete: { id: null, cep: null, endereco: null , valor: 0, tipos: [] }
    });
}
else
{
    let pedido = Cookies.getJSON('pedido');
    if(!pedido.frete.hasOwnProperty('valor') || !pedido.frete.hasOwnProperty('tipos'))
    {
        pedido['frete'] = { id: null, cep: null, endereco: null , valor: 0, tipos: [] };
    }
    if(!pedido.hasOwnProperty('valor_produtos'))
    {
        pedido['valor_produtos'] = 0;
    }
    if(!pedido.hasOwnProperty('total'))
    {
        pedido['total'] = 0;
    }

    Cookies.set('pedido', pedido);
}

if(!Cookies.get('carrinho'))
{
    Cookies.set('carrinho', {});
}

export var cliente = $.Deferred();
export var produtos = $.Deferred();

export var host = window.location.host;

export function alerta(tipo, mensagem)
{
    if(tipo == 'sucesso')
    {
        iziToast.show({
            theme: 'dark',
            title: 'Sucesso!',
            message: mensagem,
            color: 'green',
            pauseOnHover: true,
            timeout: 5000,
            position: 'topCenter'
        });
    }

    if(tipo == 'erro')
    {
        iziToast.show({
            theme: 'dark',
            title: 'Erro!',
            message: mensagem,
            color: 'red',
            pauseOnHover: true,
            timeout: 5000,
            position: 'topCenter'
        });
    }
}

export function template_produtos(categoria,produtos){
    return `
        ${produtos.map(produto => `
            <div class="produto" data-produtoid="${produto.id}">

                    <a href="http://${host}/produto/${produto.slug}" class="produto__thumb">
                        ${(produto.imagems.length > 0)
                        ? `<img src="${produto.imagems[0].uri}" alt="" class="produto__img">`
                        : `<img src="http://${host}/assets/img/foto-carne.jpg" alt="" class="produto__img">`}
                    </a>
                    <span class="produto__categoria">${categoria.titulo}</span>
                    <a href="http://${host}/produto/${produto.slug}" class="produto__nome">${produto.titulo}</a>
                    ${produto.embalagem ? `
                    <span class="produto__embalagem">${produto.embalagem.titulo}</span>
                    `:`
                    <span class="produto__embalagem">&nbsp;</span>
                    `}
                    ${parseFloat(produto.preco_promocional) ? `
                    <span class="produto__preco produto__preco--desconto">
                        <span class="produto__moeda">R$</span>
                        <span class="produto__precoantigo">${produto.preco.replace('.',',')}</span>
                        <span class="preconovo">${produto.preco_promocional.replace('.',',')}</span>
                    </span>`:`
                    <span class="produto__preco"><span class="produto__moeda">R$</span> ${produto.preco.replace('.',',')}</span>
                    `}
                <div class="produto__acoes produto__acoes--more">
                    <a href="" class="produto__remover" aria-label="Remover"><span>Remover</span></a>
                    <a href="" class="produto__subtrair" aria-label="Subtrair"><span>Subtrair</span></a>
                    <div class="produto__quantidade">
                        <input type="text" class="produto__input" value="0" type="number" min="0">
                    </div>
                    <a href="" class="produto__adicionar" aria-label="Adicionar"><span>Adicionar</span></a>
                </div>
                <div class="produto__acoes produto__acoes--less">
                    <a href="" class="produto__comprar" arial-title="Comprar"><span>Comprar</span></a>
                    <a href="" class="produto__adicionar" aria-label="Adicionar"><span>Adicionar</span></a>
                </div>
            </div>
        `).join('')}
    `;
}

export function template_cartoes(cartoes){
    return `
        ${cartoes.map(cartao => `
        <div class="cartao__item">
            <a href="#" class="cartao__button" data-id="${cartao.id}">
                <span class="cartao__remover" data-cartaoid="${cartao.id}"></span>
                <span class="cartao__number"> **** ${cartao.terminacao}</span>
                <span class="cartao__validade">${cartao.mes}/${cartao.ano}</span>

                <div class="cartao__band">
                    <img src="${cartao.bandeira_image}" alt="Visa">
                </div>
            </a>
        </div>
        `).join('')}
    `;
}