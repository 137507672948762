import * as _ from 'underwater';

export const toggleClass = (seletor, classe) => {

    document.querySelectorAll(seletor).forEach( (elemento) => {
        elemento.addEventListener('click', e => {
            e.preventDefault();
            elemento.classList.toggle(classe);
        });
    });
};

export const changeCurrent = (seletor, classe, onClick = () => {}) => {
    const elementos = document.querySelectorAll(seletor);

    elementos.forEach((elemento) => {
        elemento.addEventListener('click', e => {
            e.preventDefault();
            elementos.forEach( (item) => {
                item.classList.remove(classe);
            });
            elemento.classList.add(classe);

            onClick(elemento, elementos);
        });
    });
};

export const toggleCurrent = (seletor, classe, targetseletor, targetclasse) => {
    changeCurrent(seletor, classe, clicked => {
        if(clicked.classList.contains('tab__categorias')) {
            document.querySelector(targetseletor).classList.add(targetclasse);
        } else {
            document.querySelector(targetseletor).classList.remove(targetclasse);
        }
    });
};

export const changeClasses = (clickedElement, collection = {}) => {
    const el = (typeof clickedElement === 'string') ? document.querySelector(clickedElement) : clickedElement;

    if (!el) {
        console.warn(`[changeClasses] "${clickedElement}" Não foi encontrado.`);
        return;
    }

    el.addEventListener('click', e => {
        e.preventDefault();
        _.each(collection, (className, elSelector) => {
            document.querySelector(elSelector).classList.toggle(className);
        });
    });
};
