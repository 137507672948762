var config = require("./globals");
import { lista_fretes } from "./frete";

export function getProdutos(){
    return $.get(api+'/categoria/produto',function(categorias){
        document.categorias = categorias;
        document.produtos = [];
        $.each(categorias.data,function(i,categoria){
            $.each(categoria.produtos, function(i,produto){
                document.produtos[produto.id] = produto;
            });
        });
        config.produtos.resolve(document.produtos)
    });
}

$.when(getProdutos()).then( carrinho_atualiza );


// adicionar no carrinho
$('body').on('click','.produto__adicionar',function(e){
    e.preventDefault();
    let produto = $(this).closest('[data-produtoid]');
    produto.addClass('produto--added');

    let produtoid = produto.attr('data-produtoid');
    carrinho_add(produtoid);

    let $input = produto.find('.produto__input');
    let quantidade = parseInt($input.val())+1;
    $input.val(quantidade);
    carrinho_produto_quantidade(produtoid,quantidade);
})

//adicionar no carrinho e abrir
$('body').on('click','.produto__comprar',function(e){
    e.preventDefault();
    let produto = $(this).closest('[data-produtoid]');
    produto.toggleClass('produto--added');
    produto.find('.produto__input').val(1);

    let produtoid = produto.attr('data-produtoid');
    carrinho_add(produtoid);

    let $input = produto.find('.produto__input');
    let quantidade = parseInt($input.val());
    $input.val(quantidade);
    carrinho_produto_quantidade(produtoid,quantidade);

    $('.nav__carrinho').trigger('click');
})

//remover do carrinho
$('body').on('click','.produto__remover', function(e){
    e.preventDefault();
    let produto = $(this).closest('[data-produtoid]');
    produto.removeClass('produto--added');
    let produtoid = produto.attr('data-produtoid');
    produto.find('.produto__input').val(0);
    carrinho_remove(produtoid);
});

//diminuir quantidade
$('body').on('click','.produto__subtrair',function(e){
    e.preventDefault();
    let $input = $(this).siblings(".produto__quantidade").find('.produto__input');
    let quantidade = parseInt($input.val())-1;
    let produtoid = $(this).closest('[data-produtoid]').attr('data-produtoid');

    if(quantidade > 0)
    {
        $input.val(quantidade);
        carrinho_produto_quantidade(produtoid,quantidade);
    }
    else
    {
        $('.produto[data-produtoid="'+produtoid+'"]').removeClass('produto--added');
        $input.val(0);
        carrinho_remove(produtoid)
    }
})

//abre o carrinho
$('.nav__carrinho').on('click',function(e){
    e.preventDefault();
    if(!$(this).hasClass('nav__carrinho--empty'))
    {
        $('.nav__carrinho').toggleClass('nav__carrinho--active');
        $('body').toggleClass('body--clipped')
        $('.carrinho').toggleClass('carrinho--active')
    }
    else
    {
        $('.nav__carrinho').removeClass('nav__carrinho--active');
        $('body').removeClass('body--clipped')
        $('.carrinho').removeClass('carrinho--active')
    }
})

//limpar carrinho
$('.carrinho__limpar').on('click', function(e){
    e.preventDefault();
    Cookies.set('carrinho', {});
    carrinho_atualiza();
})

//finalizar carrinho
$('.carrinho__finalizar').on('click',function(e){
    let carrinho = Cookies.getJSON('carrinho');
    let pedido = Cookies.getJSON('pedido');
    console.log(pedido.frete.id);

    var itens = Object.keys(carrinho).length

    if(itens == 0 || !pedido.frete.id || pedido.valor_produtos < pedido_valor_minimo)
        e.preventDefault();

    if(!pedido.frete.id)
        config.alerta('erro','Por favor escolha uma forma de entrega');
    else if(pedido.valor_produtos < pedido_valor_minimo)
        config.alerta('erro','O valor mínimo de compra é R$'+pedido_valor_minimo+',00 em produtos.');
})

function carrinho_add(produtoid)
{
    let carrinho = Cookies.getJSON('carrinho');

    if(!carrinho.hasOwnProperty(produtoid))
    {
        carrinho[produtoid] = 1;
        Cookies.set('carrinho', carrinho);
    }
}

function carrinho_remove(produtoid)
{
    let carrinho = Cookies.getJSON('carrinho');
    if(carrinho.hasOwnProperty(produtoid))
    {
        delete carrinho[produtoid];
        Cookies.set('carrinho', carrinho);
    }
    carrinho_atualiza();
}

function carrinho_produto_quantidade(id,quantidade)
{
    let carrinho = Cookies.getJSON('carrinho');
    carrinho[id] = quantidade;
    Cookies.set('carrinho', carrinho);

    carrinho_atualiza();
}

export function carrinho_atualiza()
{
    let carrinho = Cookies.getJSON('carrinho');
    carrinho_frete();
    carrinho_atualiza_quantidade(carrinho);
    carrinho_atualiza_valor(carrinho);
    carrinho_listar_itens(carrinho);
    carrinho_marca_selecionados();

    if(Object.keys(carrinho).length == 0)
        $('.nav__carrinho').trigger('click');

    return true;
}

function libera_botao_finalizar()
{
    let pedido = Cookies.getJSON('pedido');
    if('frete' in pedido)
    {
        if(pedido.frete.id && pedido.valor_produtos >= pedido_valor_minimo)
        {
            $('.carrinho__finalizar').removeClass('button--disabled');
        }
        else
        {
            $('.carrinho__finalizar').addClass('button--disabled');
        }
    }
}

function carrinho_atualiza_quantidade(carrinho)
{
    let quantidade_total = 0;
    $.each(carrinho,function(id,quantidade){
        quantidade_total += quantidade;
    });

    $('.carrinho__numero, .carrinho__itens').html(quantidade_total);
    if(quantidade_total > 0)
        $('.nav__carrinho').removeClass('nav__carrinho--empty');
    else
        $('.nav__carrinho').addClass('nav__carrinho--empty');
}

function carrinho_atualiza_valor(carrinho)
{
    let pedido = Cookies.getJSON('pedido');
    let valor_total = 0;
    let valor_frete = pedido.frete.valor;
    $.each(carrinho,function(id,quantidade){
        let produto = document.produtos[id];
        if(parseFloat(produto.preco_promocional))
            valor_total += parseFloat(produto.preco_promocional)*quantidade;
        else
            valor_total += parseFloat(produto.preco)*quantidade;
    });

    $('[data-subtotal]').html('R$ '+valor_total.toFixed(2).replace('.',','))

    let valor_produtos = valor_total;
    valor_total = parseFloat(valor_frete) + valor_total;

    $('.carrinho__total').html('R$ '+valor_total.toFixed(2).replace('.',','));
    $('[data-total]').html('R$ '+valor_total.toFixed(2).replace('.',','))


    pedido.valor_produtos = valor_produtos;
    pedido.total = valor_total;
    Cookies.set('pedido', pedido);

    libera_botao_finalizar();
}

function carrinho_listar_itens(carrinho)
{
    let produtos = [];
    $.each(carrinho,function(i,data){
        produtos[i] = document.produtos[i];
    })
    let produto_html = `
        ${produtos.map( item => `
        <div class="produto" data-produtoid="${item.id}">
            <a href="http://${config.host}/produto/${item.slug}" title="${item.titulo}" class="produto__thumb">
                ${(item.imagems.length > 0)
                ? `<img src="${item.imagems[0].uri}" alt="${item.titulo}" class="produto__img">`
                : `<img src="http://${config.host}/assets/img/foto-carne.jpg" alt="" class="produto__img">`}
            </a>
            <a href="http://${config.host}/produto/${item.slug}" class="produto__nome">${item.titulo}</a>
            <span class="produto__embalagem">Vácuo</span>
            ${parseFloat(item.preco_promocional) ? `
            <span class="produto__preco produto__preco--desconto">
                <span class="produto__moeda">R$</span>
                <span class="produto__precoantigo">${item.preco.replace('.',',')}</span>
                <span class="preconovo">${item.preco_promocional.replace('.',',')}</span>
            </span>`:`
            <span class="produto__preco"><span class="produto__moeda">R$</span> ${item.preco.replace('.',',')}</span>
            `}
            <div class="produto__acoes">
                <a href="" class="produto__remover" aria-label="Remover"><span>Remover</span></a>
                <a href="" class="produto__subtrair" aria-label="Subtrair"><span>Subtrair</span></a>
                <div class="produto__quantidade">
                    <input type="text" class="produto__input" value="${carrinho[item.id]}" type="number" min="0">
                </div>
                <a href="" class="produto__adicionar" aria-label="Adicionar"><span>Adicionar</span></a>
            </div>
        </div>
        `).join('')}
    `;
    $('.carrinho__produtos').html(produto_html);
}

function carrinho_marca_selecionados()
{
    let carrinho = Cookies.getJSON('carrinho');
    $('[data-produtoid]').removeClass('produto--added');

    $.each(carrinho,function(id,quantidade){
        let item = $('[data-produtoid="'+id+'"]');
        item.addClass('produto--added');
        item.find('.produto__input').val(quantidade);
    })
}

function carrinho_frete()
{
    let pedido = Cookies.getJSON('pedido');
    if('frete' in pedido)
    {
        lista_fretes();
        if(pedido.frete.endereco)
            $('.carrinho__rua').html(pedido.frete.endereco);

        if(pedido.frete.cep && pedido.frete.id != 1)
        {
            $.each(pedido.frete.tipos,function(i,data)
            {
                if(data.id == pedido.frete.id)
                {
                    if($('[data-fretenome]').length)
                    {
                        $('[data-fretenome]').text('Tipo de entrega: '+data.titulo)
                        $('[data-fretenome]')[0].insertAdjacentHTML('beforeend','<strong data-valorfrete></strong>');
                    }

                    $('[data-valorfrete]')
                    .html('R$ '+data.valor.toFixed(2).replace('.',','))
                    .attr('data-valorfrete',data.valor);

                    $('[data-fretedescricao]').text(data.descricao);

                    $('[name="cep"]').val(pedido.frete.cep);
                }
            })

            $('[data-frete="'+pedido.frete.id+'"]').addClass('carrinho__prazo--current');
            $('.carrinho__endereco').addClass('carrinho__endereco--disabled');
        }
        else if (pedido.frete.id)
        {
            $('[data-frete="'+pedido.frete.id+'"]').addClass('carrinho__prazo--current');
            $('.carrinho__endereco').addClass('carrinho__endereco--disabled');

            $('[data-valorfrete]')
                    .html('R$ '+parseFloat(pedido.frete.valor).toFixed(2).replace('.',','))
                    .attr('data-valorfrete',pedido.frete.valor);
        }
    }
}
