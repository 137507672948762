var config = require("./globals");

if(window.location.pathname == '/conta')
{
    config.cliente.done(function(cliente){
        cliente_dados(cliente)
    })

    function cliente_dados(cliente)
    {
        $('[name="email"]').val(cliente.email);
        $('[name="cpf"]').val(cliente.cpf);
        $('[name="telefone"]').val(cliente.telefone);
        $('[name="nome"]').val(cliente.nome);
    }

    $('.conta__form').on('submit',function(e){
        e.preventDefault();

        let dados = $(this).serialize();

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: api+'/cliente',
            headers: {"X-HTTP-Method-Override": "PUT"},
            data: dados
        })
        .done(function(response){
            $('[name="confirmacao"]').val('');
            $('[name="senha"]').val('');
            $('[name="confirmasenha"]').val('');

            config.alerta('sucesso','Dados alterados com sucesso!');
        })
        .fail(function(response){
            config.alerta('erro','Ops! Aconteceu um erro, tente novamente mais tarde!');
        })

    })

    $('.cartao__list').on('click','.cartao__item a',function(e){
        e.preventDefault();
    })

    $.get('/api/cliente/cartao', function(cartoes){
        if(cartoes.length)
        {    
            let cartoes_html = config.template_cartoes(cartoes);
            $('.cartao__list').html(cartoes_html);
        }
    })
}