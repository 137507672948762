var config = require("./globals");
import { carrinho_atualiza } from "./carrinho";

//liberar edição do frete
$('.carrinho__alterar-cep').on('click',function(e){
    e.preventDefault();
    $('.carrinho__endereco').removeClass('carrinho__endereco--disabled');
    $('.carrinho__cep input').trigger('focus');
})

//cep do frete
$('.carrinho__cep').on('submit',function(e){
    e.preventDefault();
    let cep = $('[name="cep"]').val();
    
    $.get(api+'/frete/calcular/'+cep,function(retorno){ 
        if(!retorno.error)
        {
            let pedido = Cookies.getJSON('pedido');
            pedido.frete.cep = cep;
            pedido.frete.tipos = retorno.data;
            Cookies.set('pedido', pedido);

            busca_cep();
            busca_fretes();
        }
    })
    .fail(function(err){
        limpa_frete();
        config.alerta('erro',err.responseJSON.message);
    });
});

//tipo de frete
$('.carrinho__entrega').on('click','.carrinho__prazo', function(e){
    e.preventDefault();
    $('.carrinho__prazo').removeClass('carrinho__prazo--current');
    let pedido = Cookies.getJSON('pedido');
    pedido.frete.id = $(this).attr('data-frete');
    pedido.frete.titulo = $(this).attr('data-frete_titulo');
    pedido.frete.valor = $(this).attr('data-frete_valor');
    Cookies.set('pedido', pedido);
    carrinho_atualiza();
    verifica_entrega(pedido.frete.id);
})

function busca_cep()
{
    let pedido = Cookies.getJSON('pedido');
    var cep = pedido.frete.cep.match(/\d+/g).join('');
    $.get('https://viacep.com.br/ws/'+cep+'/json',function(response){
        if(!response.erro)
        {
            let cep_descricao = response.logradouro+' - '+response.bairro;
            pedido.frete.endereco = cep_descricao;
            pedido.frete.bairro = response.bairro;
            Cookies.set('pedido', pedido);
            $('.carrinho__rua').html(cep_descricao).show();
        }
    });
}

function busca_fretes()
{
    let pedido = Cookies.getJSON('pedido');

    if(!pedido.frete.tipos[0])
    {
        $.get(api+'/frete',function(retorno){

            $.each(retorno.data, function(i,el){
                if(el.id == 1)
                    pedido.frete.tipos.push(el);
            })
            Cookies.set('pedido', pedido);

            carrinho_atualiza();
        });
    }
    else
    {
        carrinho_atualiza();
    }
}

export function lista_fretes()
{
    let pedido = Cookies.getJSON('pedido');
    let html = '<h4 class="carrinho__subtitulo">Formas de entrega</h4>';

    $.each(pedido.frete.tipos,function(i,data){
        html += '<span class="carrinho__prazo" data-frete="'+data.id+'"  data-frete_titulo="'+data.titulo+'" data-frete_valor="'+data.valor+'">'+data.titulo+'</span>';
        html += '<small>'+data.descricao+'</small>';
    })
    

    $('.carrinho__entrega').html(html).show();

    if(!pedido.frete.cep)
    {
        $('.carrinho__prazo').each(function(i,el){
            if($(el).attr('data-frete') != 1)
                $(el).hide();
        })
    }
    else
    {
        $('[name="cep"]').val(pedido.frete.cep);
        $('.carrinho__endereco').addClass('carrinho__endereco--disabled');
    }
}

function limpa_frete()
{
    let pedido = Cookies.getJSON('pedido');
    $('[name="cep"]').val('');
    pedido.frete.id = 0;
    pedido.frete.titulo = '';
    pedido.frete.cep = '';
    pedido.frete.bairro = '';
    $('.carrinho__prazo').removeClass('carrinho__prazo--current');
    $('[data-valorfrete]')
                    .html('R$ 0.00')
                    .attr('data-valorfrete',0);

    Cookies.set('pedido', pedido);

    let carrinho = Cookies.getJSON('carrinho');
    // carrinho_atualiza_valor(carrinho);
}

function verifica_entrega(frete_id)
{
    $.get(api+'/horario/frete/'+frete_id,function(res){
        var {
            aberto,
            previsao
        } = res.data;
    
        const result = {
            aberto: aberto,
            previsao: previsao
        };
    
        console.log(result);
    })
    .fail(function(err){
        console.log(err)
    });
}