var config = require("./globals");
if(window.location.pathname == '/pedido/endereco')
{
    let token = Cookies.get('token');
    var itens = Object.keys(Cookies.getJSON('carrinho')).length

    if(!token || itens == 0)
    {
        window.location = '/';
    }

    $.get(api+'/endereco',function(response){
        let enderecos = response.data;
        let enderecos_html =
            `${enderecos.map(endereco => `
                <div class="endereco ${endereco.entrega ?``:` endereco--disabled`}" data-enderecoid="${endereco.entrega?endereco.id:0 }">
                    <div class="endereco__header">
                        <span>${endereco.titulo}</span>
                        ${endereco.entrega ?``:`<a href="#" class="endereco__remover" data-enderecoid="${endereco.id}"></a>`}
                    </div>
                    <div class="endereco__body">
                        ${endereco.logradouro}, ${endereco.numero}, ${endereco.bairro} <br>
                        CEP ${endereco.cep}
                        ${endereco.referencia ? `<br>
                        Referência: ${endereco.referencia}`:` `}
                    </div>
                    ${endereco.entrega ?``:`
                    <div class="endereco__indisponivel">
                        Indisponível para entrega!
                    </div>
                    `}
                </div>
            `).join('')}
        `;
        $('.enderecos').append(enderecos_html);
    });


    $('.endereco__cadastrar').on('click',function(e){
        e.preventDefault();
        $('.pedido__form').slideDown();
        $('.form__endereco [name="cep"]').trigger('change');
    });

    $('.enderecos').on('click','.endereco', function(e){
        e.preventDefault();
        let endereco_id = $(this).attr('data-enderecoid');
        if(parseInt(endereco_id))
        {
            set_pedido(endereco_id);
            window.location = '/pedido/pagamento';
        }
    })

    $('.pedido__form').on('submit',function(e){
        e.preventDefault();
        let dados = $(this).serialize();

        let cep = $('.form__endereco [name="cep"]').val();
    
        $.get(api+'/frete/calcular/'+cep,function(retorno){ 
            $.ajax({
                type: 'POST',
                dataType: 'json',
                url: api+'/endereco',
                data: dados
            })
            .done(function(response){
                let endereco = response.data;
                set_pedido(endereco.id);
                window.location = '/pedido/pagamento';
            })
            .fail(function(response){
                console.log('fail')
                console.log(response);
            })
        })
        .fail(function(err){
            config.alerta('erro',err.responseJSON.message);
        });

        
    })

    function set_pedido(endereco_id)
    {
        let pedido = Cookies.getJSON('pedido');
        pedido.endereco_id = endereco_id;
        Cookies.set('pedido', pedido);
    }
}

$('.steps__item--endereco.steps__item--ok').on('click',function(e){
    e.preventDefault();
    window.location = '/pedido/endereco';
})

$('.form__endereco [name="cep"]').on('change',function(){
    let cep = $(this).val();
    if(cep)
    {
        cep = cep.match(/\d+/g).join('');
        $(this).parent().addClass('input--active');
        $.get('https://viacep.com.br/ws/'+cep+'/json',function(response){
            if(!response.erro)
            {
                $('.form__endereco [name="logradouro"]').val(response.logradouro).parent().addClass('input--active');
                $('.form__endereco [name="bairro"]').val(response.bairro).parent().addClass('input--active');
            }
        });
    }
})

$('.enderecos').on('click','.endereco__remover',function(e){
    let endereco_id = $(this).attr('data-enderecoid');
    let card = $(this).closest('.endereco');
    if(confirm('Tem certeza que deseja excluir esse endereço?'))
    {
        $.ajax({
            type: 'DELETE',
            dataType: 'json',
            url: api+'/endereco/'+endereco_id
        })
        .done(function(response){
            card.fadeOut();
        })
        .fail(function(response){
            console.log('fail')
            console.log(response);
        })
    }
})
