const tabs = document.querySelector('.nav__tabs');
const categoriasContainer = document.querySelector('.content__aside');
const tabButtons = document.querySelectorAll('.tab');
const categoriaLinks = document.querySelectorAll('.categorias__link');
let isDesktop = window.innerWidth > 959;

if (categoriasContainer) {
    let tabBottom = tabs.getBoundingClientRect().bottom;

    function calculatePositions() {
        isDesktop = window.innerWidth > 959;
        tabBottom = tabs.getBoundingClientRect().bottom;
    }

    function render() {
        if (isDesktop) {
            categoriasContainer.style.top = '0';
            categoriasContainer.style.height = 'auto';

            return;
        }

        categoriasContainer.style.top = `${tabBottom}px`;
        categoriasContainer.style.height = `calc(100vh - ${tabBottom}px)`;
    }

    function update() {
        calculatePositions();
        render();
    }

    window.addEventListener('scroll', update);
    window.addEventListener('resize', update);
    window.addEventListener('load', update);

    function open() {
        categoriasContainer.classList.add('content__aside--active');
        document.querySelector('.tab__categorias').classList.add('tab--active');
        document.querySelector('.tab__ofertas').classList.remove('tab--active');
        document.body.classList.add('body--clipping');
    }

    function close() {
        categoriasContainer.classList.remove('content__aside--active');
        document.body.classList.remove('body--clipping');
        categoriasContainer.scrollTo({ top: 0 });

        document.querySelector('.tab__ofertas').classList.add('tab--active');
        document
            .querySelector('.tab__categorias')
            .classList.remove('tab--active');
    }

    tabButtons.forEach(button => {
        button.addEventListener('click', e => {
            e.preventDefault();
            const isCategorias = button.classList.contains('tab__categorias');

            if (isCategorias) {
                open();
            } else {
                close();
            }
        });
    });

    categoriaLinks.forEach(categoria => {
        categoria.addEventListener('click', () => {
            if (!isDesktop) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }

            close();
        });
    });
}
