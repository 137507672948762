$('.cartao__list').on('click','.cartao__remover',function(e){
    e.preventDefault();
    e.stopPropagation();
    let cartao_id = $(this).attr('data-cartaoid');
    let card = $(this).closest('.cartao__item');
    if(confirm('Tem certeza que deseja excluir esse cartão?'))
    {
        $.ajax({
            type: 'DELETE',
            dataType: 'json',
            url: api+'/cliente/cartao/'+cartao_id
        })
        .done(function(response){
            card.fadeOut();
        })
        .fail(function(response){
            console.log('fail')
            console.log(response);
        })
    }
})