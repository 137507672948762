import SmartBanner from 'smart-app-banner';

new SmartBanner({
    daysHidden: 7,
    daysReminder: 90,
    appStoreLanguage: 'pt-br',
    title: 'Fribal Delivery',
    author: 'Fribal Delivery',
    button: 'BAIXAR',
    store: {
        ios: 'App Store',
        android: 'Google Play',
    },
    price: {
        ios: 'GRÁTIS',
        android: 'GRÁTIS'
    }
    // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
    , icon: '/assets/icon/1024px.png' // full path to icon image if not using website icon image
    // , force: 'ios' // Uncomment for platform emulation
});
