const inputsDOM = document.querySelectorAll('.input');

if(inputsDOM) {
    inputsDOM.forEach(inputDOM => {
        const inputEl = inputDOM.querySelector('.input__field');
        
        if(!inputEl) return;

        inputEl.addEventListener('focus', () => {
            inputDOM.classList.add('input--active');
        });

        inputEl.addEventListener('blur', () => {
            if(inputEl.value === '') {
                inputDOM.classList.remove('input--active');
            }
        });

        inputEl.addEventListener('input', () => {
            if(inputEl.classList.contains('error')) {
                inputEl.classList.remove('error');
            }
            const errorMessage = inputDOM.querySelector('.error');
            if(errorMessage) {
                inputDOM.removeChild(errorMessage);
            }
            
            if(inputEl.value !== '') {
                inputDOM.classList.add('input--active');
            }
        });

        inputEl.addEventListener('change', () => {
            if(inputEl.value !== '') {
                inputDOM.classList.add('input--active');
            }
        });

        if(inputEl.value !== '') {
            inputDOM.classList.add('input--active');
        }
    });
}

const checkboxesDOM = document.querySelectorAll('.checkbox');

if(checkboxesDOM) {
    checkboxesDOM.forEach(checkboxDOM => {
        const checkboxEl = checkboxDOM.querySelector('.checkbox__field');
        const checkboxLabel = checkboxDOM.querySelector('.checkbox__label');
        checkboxEl.setAttribute('id', checkboxLabel.getAttribute('for'));

        const { classList } = checkboxDOM;
        const checkboxChange = () => {
            checkboxEl.checked ? classList.add('checkbox--checked') : classList.remove('checkbox--checked');
        };

        checkboxEl.addEventListener('change', checkboxChange);
        checkboxChange();
    });
}


const formSucesso = document.querySelector('.form__sucesso');

if(formSucesso) {
    formSucesso.querySelector('.button').addEventListener('click', e => {
        e.preventDefault();
        formSucesso.classList.add('form__sucesso--hide');
    });
}
