import { toggleCurrent, changeClasses } from './util';
import './forms';
import './banner';
import './destaques';
import './inputs';
import './categorias';
import './carrinho';
import './frete';
import './pedido_cadastro';
import './pedido_endereco';
import './pedido_pagamento';
import './pedido_resumo';
import './meus_pedidos';
import './meus_enderecos';
import './meus_cartoes';
import './minha_conta';
import './cliente';
import './contato';
import './area_de_cobertura';
import './duvidas';
import tabs from './tabs';
import mobile from './mobile';
import './categorias-mobile';
import 'rater';

tabs({
    triggers: '.tabs__link',
    targets: '.conta__secao',
    triggerClass: 'tabs__link--active',
    targetClass: 'conta__secao--current'
});

changeClasses('.usuario__perfil', {
    '.usuario__menu': 'usuario__menu--show'
});

const procurar__targets = {
    '.nav__procurar': 'nav__procurar--show',
    '.header': 'header--hidden'
};
changeClasses('.procurar__menu', procurar__targets);
changeClasses('.procurar__fechar', procurar__targets);

const nav__targets = {
    '.nav__list': 'nav__list--fixed',
    '.header__logo': 'header__logo--hidden'
};

changeClasses('.nav__menu', nav__targets);
changeClasses('.nav__item--fechar', nav__targets);
changeClasses('.nav__overlay', nav__targets);

window.addEventListener('scroll', fixedHeader);

var body = document.body;
var nav = document.querySelector('.header__nav');
var sticky = nav.offsetTop;

function fixedHeader() {
    if (window.pageYOffset > sticky) {
        nav.classList.add('nav--fixed');
        body.classList.add('body--fixed-nav');
    } else {
        body.classList.remove('body--fixed-nav');
        nav.classList.remove('nav--fixed');
    }
}

let banner_next = document.querySelector('.banner__next');

if (banner_next) {
    banner_next.addEventListener('click', e => {
        e.preventDefault();

        window.scrollTo({
            top: document.querySelector('.banner__bullets').offsetTop - 64,
            behavior: 'smooth'
        });
    });
}

// Options
var options = {
    max_value: 5,
    step_size: 1,
    selected_symbol_type: 'utf8_star', // Must be a key from symbols
    cursor: 'default',
    readonly: false,
    change_once: true // Determines if the rating can only be set once
};

$('.rating').rate(options);

$('.rating').on('change', function(ev, data) {
    let pedidoid = $(this).attr('data-pedidoid');
    // console.log(data.from, data.to);
    $.post(
        api + '/pedido/' + pedidoid + '/avaliar',
        { avaliacao: data.to },
        function(response) {
            if (!response.error) {
            }
        }
    ).fail(function(response) {
        console.log('fail');
        console.log(response);
    });
});