var config = require("./globals");

if(window.location.pathname == '/pedido/resumo')
{
    //depois que carrega os produtos, monta o resumo.
    config.produtos.done(resumo);

    let enviando = false;
    let token = Cookies.getJSON('token');
    var itens = Object.keys(Cookies.getJSON('carrinho')).length
    let pedido = Cookies.getJSON('pedido');

    if(!token || itens == 0 || !pedido.frete.id || !pedido.frete.cep || pedido.valor_produtos < pedido_valor_minimo)
    {
        window.location = '/';
    }

    function resumo()
    {
        ajuste_json_pedido();
        lista_itens();
        endereco();
        pagamento();
    }

    function ajuste_json_pedido()
    {
        let carrinho = Cookies.getJSON('carrinho');
        let pedido = Cookies.getJSON('pedido');

        pedido.carrinho = [];
        pedido.cstg_session_id = $('[name=cstg_session_id]').val();

        $.each(carrinho, function(produto_id,quantidade){
            pedido.carrinho.push({produto_id:produto_id, quantidade:quantidade});
        })

        Cookies.set('pedido', pedido);
    }

    function lista_itens()
    {
        let carrinho = Cookies.getJSON('carrinho');
        let produtos = [];

        $.each(carrinho,function(i,data){
            produtos[i] = document.produtos[i];
        })

        let produto_html = `
            ${produtos.map( item => `
                <div class="resumo__item">
                    <div class="resumo__thumb"><img src="{{url('img/foto-carne.jpg')}}" alt="${item.titulo}"></div>
                    <div class="resumo__dados">
                        <span class="resumo__nome">${item.titulo}</span>
                        <span class="resumo__embalagem">${item.embalagem.titulo}</span>
                        ${parseFloat(item.preco_promocional) ? `
                        <span class="resumo__preco">R$ <del>${item.preco.replace('.',',')}</del> <strong>${item.preco_promocional.replace('.',',')}</strong></span>`:
                        `<span class="resumo__preco">R$ <strong>${item.preco.replace('.',',')}</strong></span>`}
                    </div>
                    <span class="resumo__quantidade"><strong>${carrinho[item.id]}</strong> Unid.</span>
                </div>
                `).join('')}
        `;

        $('.resumo__itens').html(produto_html);
    }

    function endereco()
    {
        let pedido = Cookies.getJSON('pedido');

        $.get(api+'/endereco/'+pedido.endereco_id,function(endereco){
            $('[data-endereco-titulo]').html(endereco.titulo)
            $('[data-endereco-cep]').html(endereco.cep)
            $('[data-endereco-logradouro]').html(endereco.logradouro)
            $('[data-endereco-numero]').html(endereco.numero)
            $('[data-endereco-bairro]').html(endereco.bairro)
            $('[data-endereco-referencia]').html(endereco.referencia)
        });
    }

    function pagamento()
    {
        $('.online').hide();
        let pedido = Cookies.getJSON('pedido');
        $('[data-pagamento-tipo]').html(pedido.pagamento.titulo)

        if(pedido.pagamento.tipo == 3 )
        {
            $('.online').show();
            $('[data-cartao-nome]').html(pedido.pagseguro.titular)
            $('[data-cartao-numero]').html(pedido.pagseguro.cartao)
            $('[data-cartao-vencimento]').html(pedido.pagseguro.vencimento)
        }

        if(pedido.pagamento.tipo == 4 )
        {
            // $('.online').show();
            // $('[data-cartao-nome]').html(pedido.pagseguro.titular)
            // $('[data-cartao-numero]').html(pedido.pagseguro.cartao)
            // $('[data-cartao-vencimento]').html(pedido.pagseguro.vencimento)
        }

    }

    $('.pedido__confirmar').on('click',function(e){
        e.preventDefault();
        let pedido = Cookies.getJSON('pedido');

        // Especifica o ambiente utilizado para realizar o pedido;
        pedido.origem = "site";

        if(!enviando)
        {
            enviando = true;

            $('.pedido__confirmar').addClass('button--loading');
    
            $.post(api+'/pedido',pedido,function(response){
                if(!response.error)
                {
                    $('.pedido__content').addClass('pedido__content--sucesso');
                    $('[data-pedidoid]').html('#'+response.data.id)
    
                    Cookies.set('pedido', {endereco_id:null, frete: {id:null,cep:null}});
                    Cookies.set('carrinho', {});
                }
            })
            .fail(function(response){
                // console.log('fail')
                config.alerta('erro',response.responseJSON.message);
            })
            .always(function(){
                enviando = false;
                $('.pedido__confirmar').removeClass('button--loading');
            })
        }
    })
}
