var config = require("./globals");

if(window.location.pathname == '/pedido/pagamento')
{
    let token = Cookies.getJSON('token');
    var itens = Object.keys(Cookies.getJSON('carrinho')).length
    var creditcard = {};
    var botao_avancar = document.querySelector('.button.cartao__action');
    botao_avancar.disabled = true;

    if(!token || itens == 0)
    {
        window.location = '/';
    }

    $('.pagamento__link').on('click',function(e){
        e.preventDefault();
        let pagamento_id = $(this).attr('data-pagamentoid');
        let pagamento_titulo = $(this).html();

        let pedido = Cookies.getJSON('pedido');
        pedido.pagamento = { tipo:pagamento_id, troco:0, titulo:pagamento_titulo};
        Cookies.set('pedido', pedido);

        if(pagamento_id == 3 || pagamento_id == 4) //pagseguro e rede
        {
            $('.pedido__form').hide();
            $('.cartao').show();
            // $('.cartao__band').hide();
            $('.endereco_cobranca').hide();
        }
        else
        {
            window.location = '/pedido/resumo';
        }
    })

    //pagseguro
    // $.get('/api/pagamento/pagseguro/sessao',function(response){
    //     PagSeguroDirectPayment.setSessionId(response.token);

    //     PagSeguroDirectPayment.onSenderHashReady(function(response){
    //         if(response.status == 'error') {
    //             console.log(response.message);
    //             return false;
    //         }
    //         // console.log(response)
    //         creditcard.sender_hash = response.senderHash; //Hash estará disponível nesta variável.
    //     });

    //     // var senderHash = PagSeguroDirectPayment.getSenderHash();
    // })

    var verifica_bandeira = false;
    $('[name=cartao_numero]').on('change',function(e){

        set_valida_bandeira(true);

        // let cartao = $(this).val().replace(/\s/g, '');

        // if(cartao.length == 16 && !verifica_bandeira)
        // {
        //     verifica_bandeira = true;
        //     setTimeout(function(){ verifica_bandeira = false }, 10000)

        //     PagSeguroDirectPayment.getBrand({
        //         cardBin: cartao,
        //         success: bandeira,
        //         error: bandeira_error
        //     });
        // }
    })

    var bandeira_erro = false;
    function bandeira_error(error)
    {
        if(!bandeira_erro)
        {
            config.alerta('erro','Número do cartão inválido');
            bandeira_erro = true;
            set_bandeira_valida(false);
            setTimeout(function(){ bandeira_erro = false }, 5000)
        }
    }
    var bandeira_valida = false;
    function bandeira(retorno)
    {
        creditcard.bandeira = retorno.brand.name;
        let img = 'https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/68x30/'+retorno.brand.name+'.png';
        // $('.cartao__band').show();
        // $('.cartao__band img').attr('src',img);
        $('[data-cartaoband]').html('<img src="'+img+'" alt="Visa">');

        let pedido = Cookies.getJSON('pedido');
        set_valida_bandeira(true);
        // PagSeguroDirectPayment.getInstallments({
        //     amount: pedido.total,
        //     brand: retorno.brand.name,
        //     maxInstallmentNoInterest: 1,
        //     success: parcelamento
        // });
    }
    function set_valida_bandeira(valido) {
        botao_avancar.disabled = !valido;

        bandeira_valida = valido;
    }
    function parcelamento(retorno)
    {
        console.log(retorno);
    }

    //Cria o Hash do cartão
    $('.cartao__form').on('submit',function(e){
        e.preventDefault();

        let pedido = Cookies.getJSON('pedido');
        let cartao = $('[name=cartao_numero]').val().replace(/\s/g, '');
        if(pedido.pagamento.tipo == 3) //pagseguro
        {

            var param = {
                cardNumber: cartao,
                brand: creditcard.bandeira,
                cvv: $("[name=cvv]").val(),
                expirationMonth: $("[name=mes]").val(),
                expirationYear: $("[name=ano]").val(),
                success: successCard,
                error: errorCard
            }

            PagSeguroDirectPayment.createCardToken(param);
        }

        if(pedido.pagamento.tipo == 4) //erede
        {
            let usar_endereco_entrega = $('[name=usar_endereco_entrega]').is(':checked');
            let salvar = $('[name=save]').is(':checked');
            let endereco_cobranca = {};

            if(!usar_endereco_entrega)
            {
                endereco_cobranca = {
                    'cep': $("[name=cep]").val(),
                    'logradouro': $("[name=logradouro]").val(),
                    'numero': $("[name=numero]").val(),
                    'bairro': $("[name=bairro]").val(),
                    'complemento': $("[name=complemento]").val(),
                    'cidade': $("[name=cidade]").val(),
                    'uf': $("[name=uf]").val()
                }
            }

            pedido.pagamento.cartao = {
                'save': salvar,
                'numero': cartao,
                'mes': $("[name=mes]").val(),
                'ano': $("[name=ano]").val(),
                'nome': $("[name=nome]").val(),
                'cvc': $("[name=cvv]").val(),
                'tipo_transacao': $("[name=tipo_transacao]").val(),
                'numero_parcelas': 1,
                'usar_endereco_entrega': usar_endereco_entrega,
                'endereco_cobranca': endereco_cobranca
            }

            Cookies.set('pedido', pedido);
            window.location = '/pedido/resumo';

        }
    })

    function successCard(retorno)
    {
        if(!creditcard.sender_hash)
            creditcard.sender_hash = PagSeguroDirectPayment.getSenderHash();

        creditcard.cartao = $('[name=cartao_numero]').val();
        creditcard.vencimento = $("[name=mes]").val()+'/'+$("[name=ano]").val();
        creditcard.card_token = retorno.card.token;
        creditcard.titular = $("[name=nome]").val()
        creditcard.cpf = $("[name=cpf]").val()

        let pedido = Cookies.getJSON('pedido');
        pedido.pagseguro = creditcard;
        Cookies.set('pedido', pedido);

        window.location = '/pedido/resumo';
    }

    function errorCard()
    {
        config.alerta('erro','Dados do cartão inválidos.');
    }

    //Buscar cartoes salvos
    $('[data-section="meus_cartoes"]').hide();
    $.get('/api/cliente/cartao', function(cartoes){
        if(cartoes.length)
        {
            $('[data-section="meus_cartoes"]').show();

            let cartoes_html = config.template_cartoes(cartoes);
            $('.cartao__list').html(cartoes_html);
        }
    })

    //
    $('.cartao__list').on('click','.cartao__item a',function(e){
        e.preventDefault();

        let cartao_id = $(this).attr('data-id');

        let pedido = Cookies.getJSON('pedido');

        pedido.pagamento.cartao = {
            'id': cartao_id
        }

        Cookies.set('pedido', pedido);
        window.location = '/pedido/resumo';
    })

    // Endereco de cobranca
    $('#usar_endereco_entrega').on('change',function(e){
        let checked = $(this).is(':checked');
        if(!checked)
            $('.endereco_cobranca').slideDown();
        else
            $('.endereco_cobranca').slideUp();

    })
    

}

$('.steps__item--pagamento.steps__item--ok').on('click',function(e){
    e.preventDefault();
    window.location = '/pedido/pagamento';
})
