var config = require("./globals");

if(window.location.pathname == '/pedido/cadastro')
{
    let token = Cookies.get('token');
    var itens = Object.keys(Cookies.getJSON('carrinho')).length

    if(itens == 0)
    {
        window.location = '/';
    }

    if(token)
    {
        window.location = '/pedido/endereco';
    }
}


$('[data-formcadastro]').submit(function(e){
    e.preventDefault();
    let form = $(this);
    let data = form.serialize();
    let credencials = { email : form.find('[name="email"]').val(), senha : form.find('[name="senha"]').val() };
    let redirect = form.find('[name="redirect"]').val()

    $.post(api+'/cliente',data,function(retorno){
        // if(retorno.)
        login(credencials,redirect);
    })
    .fail(function(data){
        let response = data.responseJSON;
        if(response.error)
        {
            config.alerta('erro',response.message);

            if(response.message == 'Email já cadastrado.')
            {
                form.find('input[name="email"]')
                    .addClass('error')
                    .parent().addClass('has-error')
                    .append('<span class="help-block form-error">'+response.message+'</span>')
            }

            if(response.message == 'CPF já cadastrado.')
            {
                form.find('input[name="cpf"]')
                    .addClass('error')
                    .parent().addClass('has-error')
                    .append('<span class="help-block form-error">'+response.message+'</span>')
            }
        }
        else if(response.errors)
        {
            let first = true;

            $.each(response.errors, function(field,message){
                if(first)
                {
                    config.alerta('erro',message);
                    first = false;
                }

                form.find('input[name="'+field+'"]')
                    .addClass('error')
                    .parent().addClass('has-error')
                    .append('<span class="help-block form-error">'+message+'</span>')
            })
        }
        else
        {
            config.alerta('erro','Ops, Algo deu errado, tente novamente mais tarde.');
        }

    })
});

$('[data-password_email]').on('submit', function(e) {

    e.preventDefault();

    var form = $(this);
    var inputEmail = form.find('input[name=email]').val();

    $.ajax({
        url     : '/password/email',
        type    : form.attr('method'),
        data    : form.serialize(),
        dataType: 'json',
        success : function ( json )
        {
            if(json.error) {
                // exibe msg de erro;
                config.alerta('erro', json.message);
            } else {
                $('.error-message').html("");
                config.alerta('sucesso', 'Um link de redefinição foi enviado para [' + inputEmail + '].');

                form[0].remove();
                $('.error-message').html("Pronto. Enviamos um email com as instruções para redefinir sua senha.");
                $('.error-message').after(
                    '<div class="form__group">' +
                    '<button onclick="window.location.href=\'/\'" class="button cadastro__enviar" type="button">Página Inicial</button>' +
                    '</div>'
                );
            }
            form[0].reset();
        },
        error: function( json )
        {
            var response = json.responseJSON;

            if(json.status === 422) {
                $.each(response.errors, function (key, value) {
                    form.find('input[name="'+key+'"]')
                        .addClass('error')
                        .parent().addClass('has-error')
                        .append('<span class="help-block form-error">' + value + '</span>');
                });
            } else {
                // Error
                config.alerta('erro', response.message);
            }
        }
    });
});

$('[data-password_reset]').submit(function(e) {

    e.preventDefault();

    var form = $(this);
    var confirmaSenha = form.find('input[name=confirmasenha]');

    $.ajax({
        url     : '/password/reset',
        type    : form.attr('method'),
        data    : form.serialize(),
        dataType: 'json',
        success : function ( json )
        {
            if(json.error) {
                // exibe msg de erro;
                config.alerta('erro', json.message);
                confirmaSenha.html("");
            } else {
                config.alerta('sucesso', 'A sua senha foi redefinida.');
                // remove form do DOM e mostra botão de Login;
                form[0].remove();
                $('.error-message').html("Sua senha foi redefinida.");
                $('.error-message').after(
                    '<div class="form__group">' +
                    '<button onclick="window.location.href=\'/login\'" class="button cadastro__enviar" type="button">Efetuar Login</button>' +
                    '</div>'
                );
            }
        },
        error: function( json )
        {
            var response = json.responseJSON;

            if(json.status === 422) {
                $.each(response.errors, function (key, value) {
                    form.find('input[name="'+key+'"]')
                        .addClass('error')
                        .parent().addClass('has-error')
                        .append('<span class="help-block form-error">' + value + '</span>');
                });
            } else {
                // Error
                config.alerta('erro', response.message);
            }
        }
    });
});

$('[data-formlogin]').submit(function(e){
    e.preventDefault();

    let form = $(this);
    let credencials = $(this).serialize();
    let redirect = form.find('[name="redirect"]').val()
    login(credencials,redirect);
});

function login(credencials,redirect)
{
    $.post(api+'/auth/login', credencials,function(response){
        if(response.token)
        {
            Cookies.set('token', response.token);
            window.location = redirect;
        }
    },'json')
    .fail(function(e){
        config.alerta('erro','E-mail e/ou senha inválidos.');
    })
}
