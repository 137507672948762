var config = require("./globals");

if(window.location.pathname == '/contato')
{
    $('.contato__content').on('submit','form', function(e){
        e.preventDefault();

        let form = $(this);
        let dados = $(this).serialize();

        form.find('button').addClass('button--loading');

        $.post(api+'/contato',dados,function(response){
            form.find('input:text, input:password, select, textarea').val('');
            config.alerta('sucesso',response.message);
        })
        .fail(function(data){
            let response = data.responseJSON;
            
            let error = response.errors[Object.keys(response.errors)[0]];
            let message = error[0];
            
            config.alerta('erro',message);
            
        },'json')
        .always(function(){
            form.find('button').removeClass('button--loading');
        })
    })
}
