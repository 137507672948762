var config = require("./globals");

let token = '';

if(!Cookies.get('token'))
{
    Cookies.set('token', '');
}
else
{
    token = Cookies.get('token');

    if(token)
    {
        $.get(api+'/cliente',function(response){
            let names = response.nome.split(' ');
            let firstname = names[0];
            $('.usuario__perfil').html(firstname);
            $('.header__usuario').addClass('usuario--logado');
            // $('.nav__item--sair').addClass('nav__item--sair');
            config.cliente.resolve(response)
        }).fail(function(response){
            Cookies.set('token', '');
            if(window.location.pathname != '/')
                window.location = '/';
        })

        $('.nav__menu').on('click',function(e){
            $('.nav__item--sair').show();
        })

        $('.nav__item--fechar').on('click',function(e){
            $('.nav__item--sair').hide();
        })
    }

}

if(window.location.pathname == '/login' || window.location.pathname == '/cadastro')
{
    if(token)
    {
        window.location = '/pedidos';
    }
}

$('.usuario__link--sair,.nav__item--sair').on('click',function(e){
    e.preventDefault();
    Cookies.set('token', '');
    window.location = window.location;
})
