var config = require("./globals");
import Vue from 'vue';

if($('body').hasClass('duvidas'))
{
    const duvidas = new Vue ({
        el: '#duvidas',
        data: {
            busca: '',
            topicos: []
        },
        computed: {
            filteredList() {
                let busca = this.busca;
                let resultado = [];
    
                this.topicos.forEach(function(topico){
                    let duvidas = topico.duvidas.filter(duvida => {
                        return duvida.pergunta.toLowerCase().includes(busca.toLowerCase())
                    })
                    if(duvidas.length > 0)
                    {
                        resultado.push({
                            'titulo' : topico.titulo,
                            'duvidas' : duvidas
                        })
                    }
                })
    
                return resultado;
            }
        },
        methods: {
            fetchTopicos() {
                $.getJSON(window.location.protocol+'//'+config.host+'/assets/json/duvidas.json',function(response){
                    duvidas.topicos = response;
                });
            }
        },
        created(){
            this.fetchTopicos();
        }
    })
}
  